import clsx from 'clsx';
// eslint-disable-next-line no-restricted-imports
import { StaticImageData } from 'next/image';
import React, { HTMLAttributes } from 'react';

import { Image } from '@/components/image';

import styles from './PartsPaymentHeroBlock.module.scss';

//TODO: add ReactNode support to icons
type Props = HTMLAttributes<HTMLDivElement> & {
  header: {
    title: string;
    description: string;
    icon?: StaticImageData;
  };
  items: { title: string; description: string; icon?: StaticImageData }[];
  backgroundColor: string;
};

export const PartPaymentHeroBlock = ({
  className,
  header,
  items,
  backgroundColor,
  ...props
}: Props) => {
  const { icon, title, description } = header;

  return (
    <section className={clsx(styles.hero, className)} {...props}>
      <div className={styles.coloredBlock} style={{ backgroundColor }} />
      <div className={styles.header}>
        {icon && <Image className={styles.icon} src={icon} alt={title} width={40} height={40} />}
        <h1 className={styles.title}>{title}</h1>
        <p className={styles.description}>{description}</p>
      </div>
      <div className={styles.blocks}>
        {items.map((item) => (
          <div key={item.title} className={styles.block}>
            {item.icon && (
              <Image
                src={item.icon}
                alt={item.title}
                width={32}
                height={32}
                className={styles.image}
              />
            )}
            <div className={styles.body}>
              <h3 className={styles.title}>{item.title}</h3>
              <p className={styles.description}>{item.description}</p>
            </div>
          </div>
        ))}
      </div>
    </section>
  );
};
