import clsx from 'clsx';
import dynamic from 'next/dynamic';
import { FC, HTMLAttributes } from 'react';

import { ProductCard } from '@/components/product-card';

import { analyticMetric } from '@/helpers/analytics-metric';

import { useProductCardSaveClickContext } from '@/hooks/useProductCardSaveClickContext';

import { IProductNew } from '@/types/new/products';

import styles from './ProductsGrid.module.scss';

const ProductCardSkeleton = dynamic(
  () => import('@/components/ui/loaders/Skeletons/ProductCardSkeleton/ProductCardSkeleton')
);

interface ProductsGridProps {
  products: IProductNew[];
  isLoading?: boolean;
  variant?: 'grid-3' | 'grid-5';
  analyticMetricEcommerceList?: string;
}
const ProductsGrid: FC<HTMLAttributes<HTMLDivElement> & ProductsGridProps> = ({
  className,
  products,
  isLoading = false,
  variant = 'grid-5',
  analyticMetricEcommerceList = '',
}) => {
  const { saveContext } = useProductCardSaveClickContext();
  const onAnalyticMetricClickEvent = (product: IProductNew, index: number) => {
    analyticMetric.productClick(product, analyticMetricEcommerceList, index);
    saveContext({ position: index, list: analyticMetricEcommerceList });
  };

  return (
    <div
      className={clsx(styles.grid, styles[variant], className)}
      itemScope
      itemType="https://schema.org/ItemList"
    >
      {products.map((product, index) => (
        <ProductCard
          product={product}
          key={product.slug}
          analyticMetricClickEvent={() => onAnalyticMetricClickEvent(product, index + 1)}
          analyticMetricAddToCartEvent={() =>
            analyticMetric.productAddToCartClick(product, analyticMetricEcommerceList, index + 1)
          }
          itemProp="itemListElement"
        />
      ))}

      {isLoading && (
        <>
          {[...new Array(10)].map((_, i) => (
            <ProductCardSkeleton key={i} />
          ))}
        </>
      )}
    </div>
  );
};

export default ProductsGrid;
