import clsx from 'clsx';
import ReactMarkdown from 'react-markdown';
import { ReactMarkdownOptions } from 'react-markdown/lib/react-markdown';

import styles from './PromotionMarkdown.module.scss';

export const PromotionMarkdown = ({ className, children, ...rest }: ReactMarkdownOptions) => {
  return (
    <ReactMarkdown className={clsx(styles.markdown, className)} skipHtml={false} {...rest}>
      {children}
    </ReactMarkdown>
  );
};
